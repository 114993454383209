import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import GoogleMapReact from 'google-map-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BreakLine from '../components/atoms/breakLine'
import Payment from '../components/molecules/payment'
import Const from '../const'
import useDevice from '../hooks/useDevice'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1.5rem'
  },
  parkingTitle: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  sidebarMapBox: {
    padding: theme.spacing(1),
  },
  marginBottom: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
}));

const parkings = [
  {
    name: 'チケパ 北小金駅前第1',
    address: '〒270-0013 千葉県松戸市小金きよしケ丘１丁目５−２',
    link: 'https://goo.gl/maps/Pb8EYpnw9VvAscJS7'
  },
  {
    name: 'セントラルパーキング',
    address: '〒270-0013 千葉県松戸市小金きよしケ丘１丁目２−１',
    link: 'https://goo.gl/maps/WGJaWky4LSFjWfaa7'
  },
  {
    name: '小金きよしケ丘２丁目駐車場',
    address: '〒270-0013 千葉県松戸市小金きよしケ丘２丁目１−２５',
    link: 'https://goo.gl/maps/rCga38NtCbGyXyTg7'
  },
]


const Map = () => {
  const classes = useStyles();
  const device = useDevice();

  const handleApiLoaded = ({ map, maps }) => {
    new maps.Marker({
      map,
      position: Const.defaultLatLng,
    });
  };

  return (<>
    <Typography className={classes.title} variant="h6" gutterBottom>
      アクセス
    </Typography>
    <Typography className={classes.sidebarMapBox} align="center">
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Const.googleApiKeys }}
          defaultCenter={Const.defaultLatLng}
          defaultZoom={16}
          onGoogleApiLoaded={handleApiLoaded}
        />
      </div>
    </Typography>
    <Typography className={classes.marginBottom} align="center">
      <BreakLine value={Const.address} />
      <BreakLine value={Const.parking} />
    </Typography>
    <Typography className={classes.parkingTitle} variant="subtitle1" gutterBottom>
      ご利用可能な駐車場一覧
    </Typography>
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="駐車場一覧">
        <TableHead>
          <TableRow>
            <TableCell>名前</TableCell>
            {device.startsWith('iPad') && <TableCell>住所</TableCell>}
            <TableCell>リンク</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parkings.map(({ name, address, link }) =>
            <TableRow key={name}>
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              {device.startsWith('iPad') && <TableCell>{address}</TableCell>}
              <TableCell><a href={link} rel="noreferrer" target="_blank">Mapへ</a></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Grid className={classes.marginBottom} container spacing={3}>
      <Grid item xs={12} md={12}>
        <Payment />
      </Grid>
    </Grid>
  </>);
}

export default Map;